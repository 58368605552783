

export default {
    name: "home",
    data() {
      return {
      };
    },
    methods: {
      handleClick(e) {
        this.$router.push({path:e.path});
      },
      getTagesCor(params) {
        let faNames = $('.faName');
        for(let z=0;z<faNames.length;z++){
          if(faNames[z].innerHTML.trim() == this.$route.matched[0].name.trim()){
            if(params){
              faNames[z].parentNode.parentNode.style.backgroundImage="linear-gradient(135deg, #07a7e3 0%, #32dac3 100%)"
            }else{
              faNames[z].parentNode.parentNode.style.backgroundImage="none";
            }
          }else{
            faNames[z].parentNode.parentNode.style.backgroundImage="none";
          }
       }
      },
      getInitCor(){
        this.$nextTick(()=>{
         let menu = $('.ant-menu').children();
         for(let i = 0; i<menu.length; i++){
           if(menu[i].className == 'ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-selected'){
             $('.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-selected div').css('backgroundImage','linear-gradient(135deg, #07a7e3 0%, #32dac3 100%)');
           }
         }
        })
      }
    },
    watch: {
      $route(newValue, oldValue) {
        this.getTagesCor(true);
      }
    },
    mounted(){
      this.getInitCor();
    },
  };